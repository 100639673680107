<template>
  <v-app>
    <PWAPrompt :promptOnVisit="1" :timesToShow="3" :permanentlyHideOnDismiss="false"/>
    <v-banner
        style="background-color: blue;padding: 25px"
        v-if="deferredPrompt"
        color="info"
        dark
        class="text-left"
    >
      Get our free app. It won't take up space on your phone!

      <template v-slot:actions>
        <v-btn text @click="dismiss">Dismiss</v-btn>
        <v-btn text @click="install">Install</v-btn>
      </template>
    </v-banner>
    <router-view/>
  </v-app>
</template>

<style lang="scss">
// 3rd party plugins css
@import "~@fortawesome/fontawesome-free/css/all.css";

// Main demo style scss
@import "assets/sass/style.vue";

</style>

<script>
import {OVERRIDE_LAYOUT_CONFIG} from "@/core/services/store/config.module";
import PWAPrompt from 'vue2-ios-pwa-prompt'
export default {
  name: "SurveyVue",
  components: {
    PWAPrompt
  },
  data() {
    return {
      deferredPrompt: null
    };
  },
  created() {
    window.addEventListener("beforeinstallprompt", e => {
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.deferredPrompt = e;
    });
    window.addEventListener("appinstalled", () => {
      this.deferredPrompt = null;
    });
  },
  mounted() {
    /**
     * this is to override the layout config using saved data from localStorage
     * remove this to use config only from static json (@/core/config/layout.config.json)
     */
    this.$store.dispatch(OVERRIDE_LAYOUT_CONFIG);
  },
  methods: {
    async dismiss() {
      this.deferredPrompt = null;
    },
    async install() {
      this.deferredPrompt.prompt();
    }
  }
};
</script>
