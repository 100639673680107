import ApiService from "@/core/services/api.service";
import {SET_SNACK} from "@/core/services/store/snackbar.module";
import {SET_USER_ERROR, STAFF_LIST} from "@/core/services/store/users.module";

// action types
export const GET_TREATMENT_FORMS = "GET_TREATMENT_FORMS";
export const GET_ALL_TREATMENT_FORMS = "GET_ALL_TREATMENT_FORMS";
export const GET_TREATMENT_FORM_DETAILS = "GET_TREATMENT_FORM_DETAILS";
export const GET_CONTROL_FORMS = "GET_CONTROL_FORMS";
export const GET_ALL_CONTROL_FORMS = "GET_ALL_CONTROL_FORMS";
export const SAVE_TREATMENT_FORM = "SAVE_TREATMENT_FORM";
export const UPDATE_TREATMENT_FORM = "UPDATE_TREATMENT_FORM";
export const GET_CONTROL_FORM_DETAILS = "GET_CONTROL_FORM_DETAILS";
export const UPDATE_CONTROL_FORM = "UPDATE_CONTROL_FORM";
export const SAVE_CONTROL_FORM = "SAVE_CONTROL_FORM";
export const SAVE_BULK_FORMS = "SAVE_BULK_FORMS";
export const GET_USERS = "GET_USERS";
export const GET_DASHBOARD_STATS = "GET_DASHBOARD_STATS";
export const GET_DIVISION_STATS = "GET_DIVISION_STATS";
export const CLEAR_SURVEY_ERRORS = "clearSurveyErrors";

// mutation types
export const SET_SURVEY_ERROR = "setSurveyError";
export const REMOVE_SURVEY_ERRORS = "removeSurveyErrors";

const state = {
    errors: null
};


const actions = {
    [GET_USERS](context) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.datatable("api/getusers")
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_SURVEY_ERROR, response.errors);
                });
        });
    },
    [GET_DASHBOARD_STATS](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.datatable("api/getdashboardstats?fromDate=" + payload.data.fromDate + '&toDate=' + payload.data.toDate)
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_SURVEY_ERROR, response.errors);
                });
        });
    },
    [GET_DIVISION_STATS](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.datatable("api/getdivisionstats?d=" + payload.division + '&fromDate=' + payload.data.fromDate + '&toDate=' + payload.data.toDate)
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_SURVEY_ERROR, response.errors);
                });
        });
    },
    [GET_TREATMENT_FORMS](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.datatable("api/gettforms?" + payload.q + payload.columns.join(''))
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_SURVEY_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_SURVEY_ERROR, response.errors);
                });
        });
    },
    [GET_ALL_TREATMENT_FORMS](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.datatable("api/getalltforms?" + payload.q)
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_SURVEY_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_SURVEY_ERROR, response.errors);
                });
        });
    },
    [GET_TREATMENT_FORM_DETAILS](context, id) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.get("api/gettformdetails", id)
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_SURVEY_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_SURVEY_ERROR, response.errors);
                });
        });
    },
    [GET_CONTROL_FORMS](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.datatable("api/getcforms?" + payload.q + payload.columns.join(''))
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_SURVEY_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_SURVEY_ERROR, response.errors);
                });
        });
    },
    [GET_ALL_CONTROL_FORMS](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.datatable("api/getallcforms?" + payload.q)
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_SURVEY_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_SURVEY_ERROR, response.errors);
                });
        });
    },
    [GET_CONTROL_FORM_DETAILS](context, id) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.get("api/getcformdetails", id)
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_SURVEY_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_SURVEY_ERROR, response.errors);
                });
        });
    },
    [SAVE_TREATMENT_FORM](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/savetreatmentform", payload.data).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                context.commit(SET_SURVEY_ERROR, response.errors);
            });
        });
    },
    [UPDATE_TREATMENT_FORM](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/updatetreatmentform", payload.data).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                context.commit(SET_SURVEY_ERROR, response.errors);
            });
        });
    },
    [SAVE_CONTROL_FORM](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/savecontrolform", payload.data).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                context.commit(SET_SURVEY_ERROR, response.errors);
            });
        });
    },
    [UPDATE_CONTROL_FORM](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/updatecontrolform", payload.data).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                context.commit(SET_SURVEY_ERROR, response.errors);
            });
        });
    },
    [SAVE_BULK_FORMS](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/savebulkforms", payload.data).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                context.commit(SET_SURVEY_ERROR, response.errors);
            });
        });
    },
    [CLEAR_SURVEY_ERRORS](context) {
        context.commit(REMOVE_SURVEY_ERRORS);
    },
};

const mutations = {
    [SET_SURVEY_ERROR](state, error) {
        state.errors = error;
    },
    [REMOVE_SURVEY_ERRORS](state) {
        state.errors = {};
    }
};

export default {
    state,
    actions,
    mutations
};
