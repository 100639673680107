import router from "@/router";

export default {
    data() {
        return {
            applat: '',
            appLong: '',
            t_s_3_13_filter: [
                {value: "Your eligibility"},
                {value: "Covered family members"},
                {value: "Remaining Limit"},
                {value: "Location of nearest empaneled hospitals"}
            ],
            questions: [
                {value: "Yes"},
                {value: "No"},
                {value: "Don't know"}
            ],
            t_s_3_9_filter: [
                {value: "Within five days"},
                {value: "Any time after discharge"},
                {value: "Don't know"}
            ],
            c_s_3_13_filter: [
                {value: "1. Very effective"},
                {value: "2. Effective"},
                {value: "3. Neutral"},
                {value: "4. Ineffective"},
                {value: "5. Very ineffective"}
            ],
            c_s_3_11_filter: [
                {value: "Within five days"},
                {value: "Any time after discharge"},
                {value: "There is no such facility"},
                {value: "Don't know"}
            ],
            ulFilter: [
                {value: "plots 10 Marla (Numbers)", title: "plots 10 Marla (Numbers)"},
                {value: "5 Marla (Numbers)", title: "5 Marla (Numbers)"},
                {value: "3 Marla (Numbers)", title: "3 Marla (Numbers)"},
                {value: "Other", title: "Other"}
            ],
            t_s_3_3_filter: [
                {value: "In-Patient consultation only"},
                {value: "Surgery procedures"},
                {value: "In-patient medical tests only"},
                {value: "In-Patient medicines only"},
                {value: "All of the above"},
                {value: "Others"},
                {value: "Don't know"}
            ],
            c_s_3_8_filter: [
                {value: "Empaneled hospitals"},
                {value: "Any DHQ Hospital/ THQ hospital (non-empaneled)"},
                {value: "Any Basic Health Unit/ RHC"},
                {value: "Any dispensary"},
                {value: "Others"},
                {value: "Don't know"}
            ],
            c_s_3_9_filter: [
                {value: "Once"},
                {value: "Twice"},
                {value: "Three times"},
                {value: "Until the package limit is consumed"},
                {value: "Don't know"}
            ],
            t_s_6_9_filter: [
                {value: "Payment related"},
                {value: "Staff behaviour"},
                {value: "Coverage issues"},
                {value: "Delay in service"},
                {value: "Quality of service "},
                {value: "Suggestion for the improvement of SSP"},
                {value: "Cleanliness related"},
                {value: "Other"},
            ],
            c_s_3_3_filter: [
                {value: "In-patient services (All services include consultation, accommodation, tests, medicines, surgery etc.)"},
                {value: "In-Patient consultation only"},
                {value: "Surgery procedures"},
                {value: "In-patient medical tests only"},
                {value: "In-Patient medicines only"},
                {value: "Antenatal treatment (vaccination for tetanus, routine blood tests, ultra-sound, and other necessary investigations)"},
                {value: "OPD"},
                {value: "Others"},
                {value: "Don't know"}
            ],
            t_s_3_6_filter: [
                {value: "Once"},
                {value: "Twice"},
                {value: "Three times"},
                {value: "Until the package limit is consumed"},
                {value: "Don't know"},
            ],
            t_s_4_1_filter: [
                {value: "Public Health Facility"},
                {value: "Private Health Facility"},
                {value: "Hakeem (hikmat)"},
                {value: "Homeopathy"},
                {value: "Dispenser operating a clinic"},
                {value: "Quack"},
                {value: "Dam Durood kernay waley se"},
                {value: "Other"}
            ],
            t_s_4_11_filter: [
                {value: "CNIC issues"},
                {value: "Hospital staff was not available"},
                {value: "Package limit was over"},
                {value: "Any other reason"}
            ],
            c_s_5_8_filter: [
                {value: "Hospital did not have this facility, and asked you to get it from outside"},
                {value: "In your opinion, quality of service from hospital was not up-to the mark"},
                {value: "Any other reason"}
            ],
            t_s_5_3_filter: [
                {value: "Once"},
                {value: "Twice"},
                {value: "Thrice"},
                {value: "Other"}
            ],
            t_s_5_11_filter: [
                {value: "Medical tests"},
                {value: "Medicine"},
                {value: "Doctor consultation fee"},
                {value: "Surgeon fee"},
                {value: "Other"}
            ],
            t_s_5_13_filter: [
                {value: "Hospital did not have this facility, and asked you to get it from outside"},
                {value: "In your opinion, quality of service from hospital was not up-to the mark "},
                {value: "Any other reason"}
            ],
            t_s_5_14_filter: [
                {value: "Yes, all expenses covered."},
                {value: "No, some expenses were not  covered."},
                {value: "Not sure"}
            ],
            t_s_5_15_filter: [
                {value: "Yes"},
                {value: "Delayed discharged"},
                {value: "Early Discharged"}
            ],
            t_s_5_17_filter: [
                {value: "Representative counter informed me at the discharge"},
                {value: "Hospital staff informed at the discharge"},
                {value: "No one told me"},
                {value: "I found out only when the package limit was over"},
                {value: "Others"}
            ],
            t_s_5_22_filter: [
                {value: "Yes, significantly"},
                {value: "Yes, to some extent"},
                {value: "No, not at all"}
            ],
            t_s_6_1_filter: [
                {value: "1. Very satisfied"},
                {value: "2. Satisfied"},
                {value: "3. Indifferent"},
                {value: "4. Unsatisfied"},
                {value: "5. Very unsatisfied"}
            ],
            t_s_6_2_filter: [
                {value: "Disease was not cured"},
                {value: "Re-occurrence of same disease "},
                {value: "Medicines were not made available on time"},
                {value: "Doctors were not available"},
                {value: "Others"}
            ],
            t_s_6_3_filter: [
                {value: "1. Very satisfied"},
                {value: "2. Satisfied"},
                {value: "3. Indifferent"},
                {value: "4. Unsatisfied"},
                {value: "5. Very unsatisfied"}
            ],
            t_s_6_4_filter: [
                {value: "Staff demanded money"},
                {value: "Staff was not available"},
                {value: "Staff was not cooperative"},
                {value: "Long queues"},
                {value: "Others"}
            ],
            t_s_6_6_filter: [
                {value: "Prefilled proforma available at  health desk"},
                {value: "Prime Minister Delivery Unit  complaint portal"},
                {value: "State life helpline / call Centre"},
                {value: "Mobile App"},
                {value: "Hospital representative"},
                {value: "Any other"}
            ],
            t_s_6_7_filter: [
                {value: "Yes (completely)"},
                {value: "Yes (Partially)"},
                {value: "NO"},
                {value: "Issue got worsened."}
            ],
            t_s_6_10_filter: [
                {value: "1. Very effective"},
                {value: "2. Effective"},
                {value: "3. Somewhat effective"},
                {value: "4. Ineffective"},
                {value: "5. Very ineffective"}
            ],
            c_s_6_12_filter: [
                {value: "1. Very accessible(i.e. no waiting time)"},
                {value: "2. Accessible (i.e. Less than a day waiting time)"},
                {value: "3. Moderately accessible(i.e. 1 to 3 days waiting time)"},
                {value: "4. Slightly accessible(i.e. More than 3 days waiting time)"},
                {value: "5. Not accessible at all"}
            ],
            t_s_6_14_filter: [
                {value: "1. Very accessible"},
                {value: "2. Moderately accessible"},
                {value: "3. Slightly accessible"},
                {value: "4. Not accessible at all"}
            ],
            t_s_6_13_filter: [
                {value: "1. Very effective"},
                {value: "2. Effective"},
                {value: "3. Somewhat effective"},
                {value: "4. Ineffective"},
                {value: "5. Very ineffective"}
            ],
            t_s_6_16_filter: [
                {value: "1. Excellent"},
                {value: "2. Very good"},
                {value: "3. Good"},
                {value: "4. Bad"},
                {value: "5. Very Bad"},
                {value: "6. Worst"}
            ],
            t_s_6_17_filter: [
                {value: "1. Strongly agree"},
                {value: "2. Agree"},
                {value: "3. Indifference"},
                {value: "4. Disagree"},
                {value: "5. Strongly Disagree"}
            ],
            locationQ: [
                {title: "Use already added location", value: "1"},
                {title: "Use current location", value: "2"},
            ],
            t_s_6_22_filter: [
                {value: "Just like before treatment"},
                {value: "With less intensity"},
                {value: "With least intensity"},
                {value: "Not working at all"}
            ],
            t_s_6_35_filter: [
                {value: "Increase package limit"},
                {value: "Division of financial limits between secondary and priority care diseases is not appropriate"},
                {value: "OPD to be covered"},
                {value: "Increase in services/ treatments"},
                {value: "Others"},
                {value: "Don't know"}
            ],
            t_s_4_7_filter: [
                {value: "Package limit was over"},
                {value: "Hospital does not provide those services"},
                {value: "You were asked to come later due to Patient load"},
                {value: "Any other reason"},
                {value: "Did not tell reason"}
            ],
            c_s_4_7_filter: [
                {value: "CNIC issues"},
                {value: "Hospital staff was not available"},
                {value: "Any other reason"}
            ],
            lbFilter: [
                {value: "TV"},
                {value: "Radio"},
                {value: "Print media"},
                {value: "Facebook"},
                {value: "WhatsApp"},
                {value: "Twitter"},
                {value: "YouTube"},
                {value: "LinkedIn"},
                {value: "Through special awareness campaigns launched under the program/Program official website"},
                {value: "Health facility (Hospital/ dispensary)"},
                {value: "Through friends and family"},
                {value: "Through Community/Lady Health Worker"},
                {value: "Local Counsellor"},
                {value: "Others"},
                {value: "Don't know"}
            ],
            hospitals: [
                {value: "I decided myself to come here due its  reputation"},
                {value: "A relative / friend suggested"},
                {value: "Our Doctor/GP (General practitioner) suggested"},
                {value: "Did not know, Ambulance / 1122 brought  here"},
                {value: "Basic health Unit"},
                {value: "Rural Health centre"},
                {value: "Any other"}
            ],
            degrees: [
                {value: "Business Owner", title: "Business Owner"},
                {value: "Civil Servant/Government Employee", title: "Civil Servant/Government Employee"},
                {value: "Construction Worker", title: "Construction Worker"},
                {value: "Doctor/Physician", title: "Doctor/Physician"},
                {value: "Engineer", title: "Engineer"},
                {value: "Farmer", title: "Farmer"},
                {value: "Freelancer/Independent Contractor", title: "Freelancer/Independent Contractor"},
                {value: "IT Professional", title: "IT Professional"},
                {value: "Manager", title: "Manager"},
                {value: "Nurse", title: "Nurse"},
                {value: "Professor/Teacher/Educator", title: "Professor/Teacher/Educator"},
                {value: "Retail Worker/Shop Owner", title: "Retail Worker/Shop Owner"},
                {value: "Small Business Owner", title: "Small Business Owner"},
                {value: "Technician", title: "Technician"},
                {value: "Unemployed", title: "Unemployed"},
                {value: "Retired", title: "Retired"},
                {value: "Other", title: "Other"}
            ],
            usersFilter: [],
            divisions: [
                {value: "Lahore"},
                {value: "Multan"},
                {value: "Gujranwala"},
                {value: "Faisalabad"},
                {value: "Sahiwal"},
                {value: "Rawalpindi"},
                {value: "Sargodha"}
            ],
            districts: [
                {value: "Attock district"},
                {value: "Bhawalpur district"},
                {value: "Bahawalnagar district"},
                {value: "Rahim Yar Khan district"},
                {value: "Dera Ghazi Khan District"},
                {value: "Layyah District"},
                {value: "Muzaffargarh District"},
                {value: "Rajanpur District"},
                {value: "Faisalabad District"},
                {value: "Chiniot District"},
                {value: "Toba Tek Singh District"},
                {value: "Jhang District"},
                {value: "Gujranwala District"},
                {value: "Narowal District"},
                {value: "Sialkot District"},
                {value: "Gujrat District"},
                {value: "Hafizabad District"},
                {value: "Mandi Bahauddin District"},
                {value: "Lahore District"},
                {value: "Kasur District"},
                {value: "Nankana Sahib District"},
                {value: "Sheikhupura District"},
                {value: "Multan District"},
                {value: "Lodhran District"},
                {value: "Khanewal District"},
                {value: "Vehari District"},
                {value: "Rawalpindi District"},
                {value: "Jhelum District"},
                {value: "Chakwal District"},
                {value: "Sahiwal District"},
                {value: "Pakpattan District"},
                {value: "Okara District"},
                {value: "Sargodha District"},
                {value: "Khushab District"},
                {value: "Mianwali District"},
                {value: "Bhakkar District"}
            ],
            tehsils: [
                {parent: "Attock district", value: "ATTOCK TEHSIL"},
                {parent: "Attock district", value: "FATEH JANG TEHSIL"},
                {parent: "Attock district", value: "HASAN ABDAL TEHSIL"},
                {parent: "Attock district", value: "HAZRO TEHSIL"},
                {parent: "Attock district", value: "JAND TEHSIL"},
                {parent: "Attock district", value: "PINDI GHEB TEHSIL"},
                {parent: "Bhawalpur district", value: "AHMADPUR EAST TEHSIL"},
                {parent: "Bhawalpur district", value: "BAHAWALPUR CITY TEHSIL"},
                {parent: "Bhawalpur district", value: "BAHAWALPUR SADDAR TEHSIL"},
                {parent: "Bhawalpur district", value: "HASILPUR TEHSIL"},
                {parent: "Bhawalpur district", value: "KHAIRPUR TAMEWALI TEHSIL"},
                {parent: "Bhawalpur district", value: "YAZMAN TEHSIL"},
                {parent: "Bahawalnagar district", value: "BAHAWALNAGAR TEHSIL"},
                {parent: "Bahawalnagar district", value: "CHISHTIAN TEHSIL"},
                {parent: "Bahawalnagar district", value: "FORT ABBAS TEHSIL"},
                {parent: "Bahawalnagar district", value: "HAROONABAD TEHSIL"},
                {parent: "Bahawalnagar district", value: "MINCHINABAD TEHSIL"},
                {parent: "Rahim Yar Khan district", value: "KHANPUR TEHSIL"},
                {parent: "Rahim Yar Khan district", value: "LIAQUATPUR TEHSIL"},
                {parent: "Rahim Yar Khan district", value: "RAHIM YAR KHAN TEHSIL"},
                {parent: "Rahim Yar Khan district", value: "SADIQABAD TEHSIL"},
                {parent: "Dera Ghazi Khan District", value: "DE-EXCLUDED AREA D.G KHAN"},
                {parent: "Dera Ghazi Khan District", value: "DERA GHAZI KHAN TEHSIL"},
                {parent: "Dera Ghazi Khan District", value: "KOT CHHUTTA TEHSIL"},
                {parent: "Dera Ghazi Khan District", value: "TAUNSA TEHSIL"},
                {parent: "Layyah District", value: "CHOUBARA TEHSIL"},
                {parent: "Layyah District", value: "KAROR LAL ESAN TEHSIL"},
                {parent: "Layyah District", value: "LAYYAH TEHSIL"},
                {parent: "Muzaffargarh District", value: "ALIPUR TEHSIL"},
                {parent: "Muzaffargarh District", value: "JATOI TEHSIL"},
                {parent: "Muzaffargarh District", value: "KOT ADDU TEHSIL"},
                {parent: "Muzaffargarh District", value: "MUZAFFARGARH TEHSIL"},
                {parent: "Rajanpur District", value: "JAMPUR TEHSIL"},
                {parent: "Rajanpur District", value: "RAJANPUR TEHSIL"},
                {parent: "Rajanpur District", value: "ROJHAN TEHSIL"},
                {parent: "Faisalabad District", value: "CHAK JHUMRA TEHSIL"},
                {parent: "Faisalabad District", value: "FAISALABAD CITY TEHSIL"},
                {parent: "Faisalabad District", value: "FAISALABAD SADAR TEHSIL"},
                {parent: "Faisalabad District", value: "SAMMUNDRI TEHSIL"},
                {parent: "Faisalabad District", value: "TANDLIAN WALA TEHSIL"},
                {parent: "Faisalabad District", value: "Jaranwala Tehsil"},
                {parent: "Chiniot District", value: "BHAWANA TEHSIL"},
                {parent: "Chiniot District", value: "CHINIOT TEHSIL"},
                {parent: "Chiniot District", value: "LALIAN TEHSIL"},
                {parent: "Toba Tek Singh District", value: "GOJRA TEHSIL"},
                {parent: "Toba Tek Singh District", value: "KAMALIA TEHSIL"},
                {parent: "Toba Tek Singh District", value: "PIRMAHAL TEHSIL"},
                {parent: "Toba Tek Singh District", value: "TOBA TEK SINGH TEHSIL"},
                {parent: "Jhang District", value: "18-HAZARI TEHSIL"},
                {parent: "Jhang District", value: "AHMADPUR SIAL TEHSIL"},
                {parent: "Jhang District", value: "JHANG TEHSIL"},
                {parent: "Jhang District", value: "SHORKOT TEHSIL"},
                {parent: "Gujranwala District", value: "GUJRANWALA CITY TEHSIL"},
                {parent: "Gujranwala District", value: "GUJRANWALA SADDAR TEHSIL"},
                {parent: "Gujranwala District", value: "KAMOKE TEHSIL"},
                {parent: "Gujranwala District", value: "NOWSHERA VIRKAN TEHSIL"},
                {parent: "Gujranwala District", value: "WAZIRABAD TEHSIL"},
                {parent: "Narowal District", value: "NAROWAL TEHSIL"},
                {parent: "Narowal District", value: "SHAKARGARH TEHSIL"},
                {parent: "Narowal District", value: "ZAFARWAL TEHSIL"},
                {parent: "Sialkot District", value: "DASKA TEHSIL"},
                {parent: "Sialkot District", value: "PASRUR TEHSIL"},
                {parent: "Sialkot District", value: "SAMBRIAL TEHSIL"},
                {parent: "Sialkot District", value: "SIALKOT TEHSIL"},
                {parent: "Gujrat District", value: "GUJRAT TEHSIL"},
                {parent: "Gujrat District", value: "KHARIAN TEHSIL"},
                {parent: "Gujrat District", value: "SARAI ALAMGIR TEHSIL"},
                {parent: "Hafizabad District", value: "HAFIZABAD TEHSIL"},
                {parent: "Hafizabad District", value: "PINDI BHATTIAN TEHSIL"},
                {parent: "Mandi Bahauddin District", value: "MALAKWAL TEHSIL"},
                {parent: "Mandi Bahauddin District", value: "MANDI BAHAUDDIN TEHSIL"},
                {parent: "Mandi Bahauddin District", value: "PHALIA TEHSIL"},
                {parent: "Lahore District", value: "LAHORE CANTT TEHSIL"},
                {parent: "Lahore District", value: "LAHORE CITY TEHSIL"},
                {parent: "Lahore District", value: "MODEL TOWN TEHSIL"},
                {parent: "Lahore District", value: "RAIWIND TEHSIL"},
                {parent: "Lahore District", value: "SHALIMAR TEHSIL"},
                {parent: "Kasur District", value: "CHUNIAN TEHSIL"},
                {parent: "Kasur District", value: "KASUR TEHSIL"},
                {parent: "Kasur District", value: "KOT RADHA KISHAN TEHSIL"},
                {parent: "Kasur District", value: "PATTOKI TEHSIL"},
                {parent: "Nankana Sahib District", value: "NANKANA SAHIB TEHSIL"},
                {parent: "Nankana Sahib District", value: "SANGLA HILL TEHSIL"},
                {parent: "Nankana Sahib District", value: "SHAH KOT TEHSIL"},
                {parent: "Sheikhupura District", value: "FEROZEWALA TEHSIL"},
                {parent: "Sheikhupura District", value: "MURIDKE TEHSIL"},
                {parent: "Sheikhupura District", value: "SAFDARABAD TEHSIL"},
                {parent: "Sheikhupura District", value: "SHARAK PUR TEHSIL"},
                {parent: "Sheikhupura District", value: "SHEIKHUPURA TEHSIL"},
                {parent: "Multan District", value: "JALALPUR PIRWALA TEHSIL"},
                {parent: "Multan District", value: "MULTAN CITY TEHSIL"},
                {parent: "Multan District", value: "MULTAN SADDAR TEHSIL"},
                {parent: "Multan District", value: "SHUJABAD TEHSIL"},
                {parent: "Lodhran District", value: "DUNYAPUR TEHSIL"},
                {parent: "Lodhran District", value: "KAHROR PACCA TEHSIL"},
                {parent: "Lodhran District", value: "LODHRAN TEHSIL"},
                {parent: "Khanewal District", value: "JAHANIAN TEHSIL"},
                {parent: "Khanewal District", value: "KABIRWALA TEHSIL"},
                {parent: "Khanewal District", value: "KHANEWAL TEHSIL"},
                {parent: "Khanewal District", value: "MIAN CHANNU TEHSIL"},
                {parent: "Vehari District", value: "BUREWALA TEHSIL"},
                {parent: "Vehari District", value: "MAILSI TEHSIL"},
                {parent: "Vehari District", value: "VEHARI TEHSIL"},
                {parent: "Rawalpindi District", value: "GUJAR KHAN TEHSIL"},
                {parent: "Rawalpindi District", value: "KAHUTA TEHSIL"},
                {parent: "Rawalpindi District", value: "KALLAR SAYADDAN TEHSIL"},
                {parent: "Rawalpindi District", value: "KOTLI SATTIAN TEHSIL"},
                {parent: "Rawalpindi District", value: "MURREE TEHSIL"},
                {parent: "Rawalpindi District", value: "RAWALPINDI TEHSIL"},
                {parent: "Rawalpindi District", value: "TAXILA TEHSIL"},
                {parent: "Jhelum District", value: "DINA TEHSIL"},
                {parent: "Jhelum District", value: "JHELUM TEHSIL"},
                {parent: "Jhelum District", value: "PIND DADAN KHAN TEHSIL"},
                {parent: "Jhelum District", value: "SOHAWA TEHSIL"},
                {parent: "Chakwal District", value: "CHAKWAL TEHSIL"},
                {parent: "Chakwal District", value: "CHOA SAIDAN SHAH TEHSIL"},
                {parent: "Chakwal District", value: "KALLAR KAHAR TEHSIL"},
                {parent: "Chakwal District", value: "LAWA TEHSIL"},
                {parent: "Chakwal District", value: "TALA GANG TEHSIL"},
                {parent: "Sahiwal District", value: "CHICHAWATNI TEHSIL"},
                {parent: "Sahiwal District", value: "SAHIWAL TEHSIL"},
                {parent: "Pakpattan District", value: "ARIF WALA TEHSIL"},
                {parent: "Pakpattan District", value: "PAKPATTAN TEHSIL"},
                {parent: "Okara District", value: "DEPALPUR TEHSIL"},
                {parent: "Okara District", value: "OKARA TEHSIL"},
                {parent: "Okara District", value: "RENALA KHURD TEHSIL"},
                {parent: "Sargodha District", value: "BHALWAL TEHSIL"},
                {parent: "Sargodha District", value: "BHERA TEHSIL"},
                {parent: "Sargodha District", value: "KOT MOMIN TEHSIL"},
                {parent: "Sargodha District", value: "SAHIWAL TEHSIL"},
                {parent: "Sargodha District", value: "SARGODHA TEHSIL"},
                {parent: "Sargodha District", value: "SHAHPUR TEHSIL"},
                {parent: "Sargodha District", value: "SILLANWALI TEHSIL"},
                {parent: "Khushab District", value: "KHUSHAB TEHSIL"},
                {parent: "Khushab District", value: "NOORPUR TEHSIL"},
                {parent: "Khushab District", value: "NOWSHERA TEHSIL"},
                {parent: "Khushab District", value: "QUAIDABAD TEHSIL"},
                {parent: "Mianwali District", value: "ISAKHEL TEHSIL"},
                {parent: "Mianwali District", value: "MIANWALI TEHSIL"},
                {parent: "Mianwali District", value: "PIPLAN TEHSIL"},
                {parent: "Bhakkar District", value: "BHAKKAR TEHSIL"},
                {parent: "Bhakkar District", value: "DARYA KHAN TEHSIL"},
                {parent: "Bhakkar District", value: "KALUR KOT TEHSIL"},
                {parent: "Bhakkar District", value: "MANKERA TEHSIL"}
            ],
            ftehsils: [
                {parent: "Attock district", value: "ATTOCK TEHSIL"},
                {parent: "Attock district", value: "FATEH JANG TEHSIL"},
                {parent: "Attock district", value: "HASAN ABDAL TEHSIL"},
                {parent: "Attock district", value: "HAZRO TEHSIL"},
                {parent: "Attock district", value: "JAND TEHSIL"},
                {parent: "Attock district", value: "PINDI GHEB TEHSIL"},
                {parent: "Bhawalpur district", value: "AHMADPUR EAST TEHSIL"},
                {parent: "Bhawalpur district", value: "BAHAWALPUR CITY TEHSIL"},
                {parent: "Bhawalpur district", value: "BAHAWALPUR SADDAR TEHSIL"},
                {parent: "Bhawalpur district", value: "HASILPUR TEHSIL"},
                {parent: "Bhawalpur district", value: "KHAIRPUR TAMEWALI TEHSIL"},
                {parent: "Bhawalpur district", value: "YAZMAN TEHSIL"},
                {parent: "Bahawalnagar district", value: "BAHAWALNAGAR TEHSIL"},
                {parent: "Bahawalnagar district", value: "CHISHTIAN TEHSIL"},
                {parent: "Bahawalnagar district", value: "FORT ABBAS TEHSIL"},
                {parent: "Bahawalnagar district", value: "HAROONABAD TEHSIL"},
                {parent: "Bahawalnagar district", value: "MINCHINABAD TEHSIL"},
                {parent: "Rahim Yar Khan district", value: "KHANPUR TEHSIL"},
                {parent: "Rahim Yar Khan district", value: "LIAQUATPUR TEHSIL"},
                {parent: "Rahim Yar Khan district", value: "RAHIM YAR KHAN TEHSIL"},
                {parent: "Rahim Yar Khan district", value: "SADIQABAD TEHSIL"},
                {parent: "Dera Ghazi Khan District", value: "DE-EXCLUDED AREA D.G KHAN"},
                {parent: "Dera Ghazi Khan District", value: "DERA GHAZI KHAN TEHSIL"},
                {parent: "Dera Ghazi Khan District", value: "KOT CHHUTTA TEHSIL"},
                {parent: "Dera Ghazi Khan District", value: "TAUNSA TEHSIL"},
                {parent: "Layyah District", value: "CHOUBARA TEHSIL"},
                {parent: "Layyah District", value: "KAROR LAL ESAN TEHSIL"},
                {parent: "Layyah District", value: "LAYYAH TEHSIL"},
                {parent: "Muzaffargarh District", value: "ALIPUR TEHSIL"},
                {parent: "Muzaffargarh District", value: "JATOI TEHSIL"},
                {parent: "Muzaffargarh District", value: "KOT ADDU TEHSIL"},
                {parent: "Muzaffargarh District", value: "MUZAFFARGARH TEHSIL"},
                {parent: "Rajanpur District", value: "JAMPUR TEHSIL"},
                {parent: "Rajanpur District", value: "RAJANPUR TEHSIL"},
                {parent: "Rajanpur District", value: "ROJHAN TEHSIL"},
                {parent: "Faisalabad District", value: "CHAK JHUMRA TEHSIL"},
                {parent: "Faisalabad District", value: "FAISALABAD CITY TEHSIL"},
                {parent: "Faisalabad District", value: "FAISALABAD SADAR TEHSIL"},
                {parent: "Faisalabad District", value: "SAMMUNDRI TEHSIL"},
                {parent: "Faisalabad District", value: "TANDLIAN WALA TEHSIL"},
                {parent: "Faisalabad District", value: "Jaranwala Tehsil"},
                {parent: "Chiniot District", value: "BHAWANA TEHSIL"},
                {parent: "Chiniot District", value: "CHINIOT TEHSIL"},
                {parent: "Chiniot District", value: "LALIAN TEHSIL"},
                {parent: "Toba Tek Singh District", value: "GOJRA TEHSIL"},
                {parent: "Toba Tek Singh District", value: "KAMALIA TEHSIL"},
                {parent: "Toba Tek Singh District", value: "PIRMAHAL TEHSIL"},
                {parent: "Toba Tek Singh District", value: "TOBA TEK SINGH TEHSIL"},
                {parent: "Jhang District", value: "18-HAZARI TEHSIL"},
                {parent: "Jhang District", value: "AHMADPUR SIAL TEHSIL"},
                {parent: "Jhang District", value: "JHANG TEHSIL"},
                {parent: "Jhang District", value: "SHORKOT TEHSIL"},
                {parent: "Gujranwala District", value: "GUJRANWALA CITY TEHSIL"},
                {parent: "Gujranwala District", value: "GUJRANWALA SADDAR TEHSIL"},
                {parent: "Gujranwala District", value: "KAMOKE TEHSIL"},
                {parent: "Gujranwala District", value: "NOWSHERA VIRKAN TEHSIL"},
                {parent: "Gujranwala District", value: "WAZIRABAD TEHSIL"},
                {parent: "Narowal District", value: "NAROWAL TEHSIL"},
                {parent: "Narowal District", value: "SHAKARGARH TEHSIL"},
                {parent: "Narowal District", value: "ZAFARWAL TEHSIL"},
                {parent: "Sialkot District", value: "DASKA TEHSIL"},
                {parent: "Sialkot District", value: "PASRUR TEHSIL"},
                {parent: "Sialkot District", value: "SAMBRIAL TEHSIL"},
                {parent: "Sialkot District", value: "SIALKOT TEHSIL"},
                {parent: "Gujrat District", value: "GUJRAT TEHSIL"},
                {parent: "Gujrat District", value: "KHARIAN TEHSIL"},
                {parent: "Gujrat District", value: "SARAI ALAMGIR TEHSIL"},
                {parent: "Hafizabad District", value: "HAFIZABAD TEHSIL"},
                {parent: "Hafizabad District", value: "PINDI BHATTIAN TEHSIL"},
                {parent: "Mandi Bahauddin District", value: "MALAKWAL TEHSIL"},
                {parent: "Mandi Bahauddin District", value: "MANDI BAHAUDDIN TEHSIL"},
                {parent: "Mandi Bahauddin District", value: "PHALIA TEHSIL"},
                {parent: "Lahore District", value: "LAHORE CANTT TEHSIL"},
                {parent: "Lahore District", value: "LAHORE CITY TEHSIL"},
                {parent: "Lahore District", value: "MODEL TOWN TEHSIL"},
                {parent: "Lahore District", value: "RAIWIND TEHSIL"},
                {parent: "Lahore District", value: "SHALIMAR TEHSIL"},
                {parent: "Kasur District", value: "CHUNIAN TEHSIL"},
                {parent: "Kasur District", value: "KASUR TEHSIL"},
                {parent: "Kasur District", value: "KOT RADHA KISHAN TEHSIL"},
                {parent: "Kasur District", value: "PATTOKI TEHSIL"},
                {parent: "Nankana Sahib District", value: "NANKANA SAHIB TEHSIL"},
                {parent: "Nankana Sahib District", value: "SANGLA HILL TEHSIL"},
                {parent: "Nankana Sahib District", value: "SHAH KOT TEHSIL"},
                {parent: "Sheikhupura District", value: "FEROZEWALA TEHSIL"},
                {parent: "Sheikhupura District", value: "MURIDKE TEHSIL"},
                {parent: "Sheikhupura District", value: "SAFDARABAD TEHSIL"},
                {parent: "Sheikhupura District", value: "SHARAK PUR TEHSIL"},
                {parent: "Sheikhupura District", value: "SHEIKHUPURA TEHSIL"},
                {parent: "Multan District", value: "JALALPUR PIRWALA TEHSIL"},
                {parent: "Multan District", value: "MULTAN CITY TEHSIL"},
                {parent: "Multan District", value: "MULTAN SADDAR TEHSIL"},
                {parent: "Multan District", value: "SHUJABAD TEHSIL"},
                {parent: "Lodhran District", value: "DUNYAPUR TEHSIL"},
                {parent: "Lodhran District", value: "KAHROR PACCA TEHSIL"},
                {parent: "Lodhran District", value: "LODHRAN TEHSIL"},
                {parent: "Khanewal District", value: "JAHANIAN TEHSIL"},
                {parent: "Khanewal District", value: "KABIRWALA TEHSIL"},
                {parent: "Khanewal District", value: "KHANEWAL TEHSIL"},
                {parent: "Khanewal District", value: "MIAN CHANNU TEHSIL"},
                {parent: "Vehari District", value: "BUREWALA TEHSIL"},
                {parent: "Vehari District", value: "MAILSI TEHSIL"},
                {parent: "Vehari District", value: "VEHARI TEHSIL"},
                {parent: "Rawalpindi District", value: "GUJAR KHAN TEHSIL"},
                {parent: "Rawalpindi District", value: "KAHUTA TEHSIL"},
                {parent: "Rawalpindi District", value: "KALLAR SAYADDAN TEHSIL"},
                {parent: "Rawalpindi District", value: "KOTLI SATTIAN TEHSIL"},
                {parent: "Rawalpindi District", value: "MURREE TEHSIL"},
                {parent: "Rawalpindi District", value: "RAWALPINDI TEHSIL"},
                {parent: "Rawalpindi District", value: "TAXILA TEHSIL"},
                {parent: "Jhelum District", value: "DINA TEHSIL"},
                {parent: "Jhelum District", value: "JHELUM TEHSIL"},
                {parent: "Jhelum District", value: "PIND DADAN KHAN TEHSIL"},
                {parent: "Jhelum District", value: "SOHAWA TEHSIL"},
                {parent: "Chakwal District", value: "CHAKWAL TEHSIL"},
                {parent: "Chakwal District", value: "CHOA SAIDAN SHAH TEHSIL"},
                {parent: "Chakwal District", value: "KALLAR KAHAR TEHSIL"},
                {parent: "Chakwal District", value: "LAWA TEHSIL"},
                {parent: "Chakwal District", value: "TALA GANG TEHSIL"},
                {parent: "Sahiwal District", value: "CHICHAWATNI TEHSIL"},
                {parent: "Sahiwal District", value: "SAHIWAL TEHSIL"},
                {parent: "Pakpattan District", value: "ARIF WALA TEHSIL"},
                {parent: "Pakpattan District", value: "PAKPATTAN TEHSIL"},
                {parent: "Okara District", value: "DEPALPUR TEHSIL"},
                {parent: "Okara District", value: "OKARA TEHSIL"},
                {parent: "Okara District", value: "RENALA KHURD TEHSIL"},
                {parent: "Sargodha District", value: "BHALWAL TEHSIL"},
                {parent: "Sargodha District", value: "BHERA TEHSIL"},
                {parent: "Sargodha District", value: "KOT MOMIN TEHSIL"},
                {parent: "Sargodha District", value: "SAHIWAL TEHSIL"},
                {parent: "Sargodha District", value: "SARGODHA TEHSIL"},
                {parent: "Sargodha District", value: "SHAHPUR TEHSIL"},
                {parent: "Sargodha District", value: "SILLANWALI TEHSIL"},
                {parent: "Khushab District", value: "KHUSHAB TEHSIL"},
                {parent: "Khushab District", value: "NOORPUR TEHSIL"},
                {parent: "Khushab District", value: "NOWSHERA TEHSIL"},
                {parent: "Khushab District", value: "QUAIDABAD TEHSIL"},
                {parent: "Mianwali District", value: "ISAKHEL TEHSIL"},
                {parent: "Mianwali District", value: "MIANWALI TEHSIL"},
                {parent: "Mianwali District", value: "PIPLAN TEHSIL"},
                {parent: "Bhakkar District", value: "BHAKKAR TEHSIL"},
                {parent: "Bhakkar District", value: "DARYA KHAN TEHSIL"},
                {parent: "Bhakkar District", value: "KALUR KOT TEHSIL"},
                {parent: "Bhakkar District", value: "MANKERA TEHSIL"}
            ],
            activeTab: 0,
            activeTabInner: 0,
            activeTabDetail: 0,
            activeTabDetailInner: 0,
            activeTabSecond: 0,
        };
    },
    methods: {
        goHome() {
            if (router.currentRoute.name === 'survey') {
                window.location.reload()
            } else {
                router.push({name: "survey"});
            }
        },
        checkAuth() {
            if (localStorage.getItem('id_token')) {
                return true;
            }
        },
        getUserName() {
            if (localStorage.getItem('id_name')) {
                return localStorage.getItem('id_name');
            }
        },
        getUserId() {
            if (localStorage.getItem('id_id')) {
                return localStorage.getItem('id_id');
            }
        },
        getRole() {
            if (localStorage.getItem('id_role')) {
                return localStorage.getItem('id_role');
            }
        },
        checkOldForms() {
            if (navigator.onLine) {
                if (localStorage.getItem('cItem') || localStorage.getItem('tItem')) {
                    return true;
                }
            }
        },
        saveOfflineTForm(data) {
            let tItem = JSON.parse(localStorage.getItem("tItem") || "[]");
            tItem.push(data);
            localStorage.setItem("tItem", JSON.stringify(tItem));
        },
        saveOfflineCForm(data) {
            let cItem = JSON.parse(localStorage.getItem("cItem") || "[]");
            cItem.push(data);
            localStorage.setItem("cItem", JSON.stringify(cItem));
        },
        checkTehsils(e) {
            if (e) {
                this.ftehsils = this.tehsils.filter(item => item.parent === e);
            } else {
                this.ftehsils = this.tehsils;
            }
        },
        goTotab(tab) {
            this.activeTab = tab
            setTimeout(() => {
                window.scrollTo(0, 0);
            }, 100);
        },
        isNumber(evt) {
            let charCode = evt.which ? evt.which : evt.keyCode;
            if (
                charCode !== 46 &&
                charCode > 31 &&
                (charCode < 48 || charCode > 57)
            ) {
                return evt.preventDefault();
            }
            return true;
            // if (!/^[0-9]+$/.test(event.key) || event.key === ".")
            //   return event.preventDefault();
        },
        preventLeadingSpace(e) {
            // only prevent the keypress if the value is blank
            if (!e.target.value) e.preventDefault();
            // otherwise, if the leading character is a space, remove all leading white-space
            else if (e.target.value[0] == ' ') e.target.value = e.target.value.replace(/^\s*/, "");
        },
        onPaste(evt) {
            if (isNaN(evt.clipboardData.getData('text'))) {
                evt.preventDefault();
            } else {
                return true;
            }
        }
    }
}
