import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";
import vuetify from "@/core/plugins/vuetify";
import "@mdi/font/css/materialdesignicons.css";

// import mixins
import globalMixin from "./mixins/globalMixin";
import './registerServiceWorker'

Vue.mixin(globalMixin);
ApiService.init();
// Remove this to disable mock API
// MockService.init();
// router.beforeEach((to, from, next) => {
//     if (!getToken() && auth.state.isAuthenticated) {
//         store.dispatch(LOGOUT).then(() => router.push("login"));
//         return false;
//     }
//     // Ensure we checked auth before each page load.
//     Promise.all([
//         store.dispatch(VERIFY_AUTH, to.name).then(data => {
//             if (data && data._metadata.outcomeCode === 0) {
//                 if (to.meta.auth) {
//                     if (AuthService.hasPermission(to.meta.permission)) {
//                         next();
//                     } else {
//                         router.push("login");
//                     }
//                 }
//             }
//             // console.log(AuthService.hasPermission(to.meta.permission));
//         })
//     ]).then(next);
//
//     // reset config to initial state
//     store.dispatch(RESET_LAYOUT_CONFIG);
//
//     // Scroll page to top on every route change
//     setTimeout(() => {
//         window.scrollTo(0, 0);
//     }, 100);
// });
Vue.config.productionTip = false
new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount("#app");
