import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);
export default new Router({
    routes: [
        {
            path: "/",
            component: () => import("@/view/pages/auth/Auth"),
            children: [
                {
                    name: "login",
                    path: "/",
                    component: () => import("@/view/pages/auth/Login")
                }
            ]
        },
        {
            path: '/survey',
            name: 'survey',
            component: () => import('./view/pages/realstate/survey/Survey.vue')
        },
        {
            path: '/dashboard',
            name: 'dashboard',
            component: () => import('./view/pages/realstate/survey/Dashboard.vue')
        },
        {
            path: '/treatment-forms',
            name: 't_forms',
            component: () => import('./view/pages/realstate/survey/TreatmentSurveys.vue')
        },
        {
            path: '/control-forms',
            name: 'c_forms',
            component: () => import('./view/pages/realstate/survey/ControlSurveys.vue')
        }
    ]
});
