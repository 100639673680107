const ID_TOKEN_KEY = "id_token";
const USER_NAME_KEY = "id_name";
const USER_ROLE_KEY = "id_role";
const USER_ID_KEY = "id_id";

export const getToken = () => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const getUserId = () => {
  return window.localStorage.getItem(USER_ID_KEY);
};

export const getUserName = () => {
  return window.localStorage.getItem(USER_NAME_KEY);
};

export const getUserRole = () => {
  return window.localStorage.getItem(USER_ROLE_KEY);
};

export const saveToken = token => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

export const saveUserId = id => {
  window.localStorage.setItem(USER_ID_KEY, id);
};

export const saveUserName = name => {
  window.localStorage.setItem(USER_NAME_KEY, name);
};

export const saveUserRole = role => {
  window.localStorage.setItem(USER_ROLE_KEY, role);
};

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
};

export default { getToken, saveToken, destroyToken, saveUserId, saveUserName, saveUserRole, getUserId, getUserName, getUserRole };
